<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 交易数据 -->
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>交易数据</p>
            <chart-timer-picker active-tag="yesterday" :tags="['yesterday', 'today', 'day7', 'day30']" @time-change="handleTimeChangeTrade"/>
          </div>
          <div class="echart-alltitle">
            <div class="echarts-left50">
              <en-table-layout :toolbar="false" :tableData="tableData.parta" :loading="loading">
                <template slot="table-columns">
                  <el-table-column prop="pv_num" label="平台访问量(pv)" />
                  <el-table-column prop="uv_num" label="浏览人数(uv)" />
                  <el-table-column prop="order_people" label="下单人数" />
                  <el-table-column prop="order_num" label="订单数" />
                  <el-table-column prop="order_money" label="订单金额" />
                </template>
              </en-table-layout>
              <en-table-layout :toolbar="false" :tableData="tableData.partb" :loading="loading">
                <template slot="table-columns">
                  <el-table-column prop="pay_people" label="付款人数" />
                  <el-table-column prop="pay_orader" label="付款订单数" />
                  <el-table-column prop="pay_money" label="付款金额" />
                  <el-table-column prop="money" label="客单价" />
                  <el-table-column prop="refund_money" label="退款金额" />
                </template>
              </en-table-layout>
            </div>
            <div :span="12" id="echarts_right" class="echarts-right" style="width:50%;"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>订单数据</p>
            <chart-timer-picker active-tag="week" :tags="['week', 'month1', 'year1']" @time-change="handleTimeChangeOrder"/>
          </div>
          <div class="echart-alltitle">
            <div class="echarts-left1">
              <div class="echarts-left">
                <h2>{{Number(order.order_paysum)}}</h2>
                <p>{{activeName}}成交订单数</p>
              </div>
              <div class="echarts-left">
                <h2>{{Number(order.refundpaysum)}}</h2>
                <p>{{activeName}}退款订单数</p>
              </div>
            </div>
            <div :span="20" id="echarts_right_order" class="echarts-right"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 0">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="clearfix echart-title">
            <p>商户/分销商新增数据统计</p>
            <chart-timer-picker active-tag="month1" :tags="['month1', 'month3', 'year1']" @time-change="handleTimeChangeShop"/>
          </div>
          <div class="echart-alltitle">
            <div class="echarts-left1">
              <div class="echarts-left">
                <h2>{{Number(shop.shop_num)}}</h2>
                <p>{{activeName2}}新入驻商户</p>
              </div>
              <div class="echarts-left">
                <h2>{{Number(shop.dshop_num)}}</h2>
                <p>{{activeName2}}新增分销商</p>
              </div>
            </div>
            <div :span="20" id="echarts_right_shop" class="echarts-right"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 0">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="clearfix echart-title">
            <p>交易订单单价数据</p>
            <el-cascader
              style="width:19%;"
              expand-trigger="hover"
              :options="typeList"
              @change="activityTypeChange"
              :clearable="true"
              size="mini"
            ></el-cascader>
            <chart-timer-picker active-tag="week" :tags="['week', 'month1', 'year1']" @time-change="handleTimeChangePrice"/>
          </div>
          <div class="echart-alltitle">
            <div :span="20" id="echarts_right_price" class="echarts-right" style="width:100%;"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as API_Home from "@/api/home";
import * as API_Sta from "@/api/$sta.trade.js";
import ChartTimerPicker from '../chart-timer-picker';
import * as API_activityType from "@/api/activityType";
export default {
  name: "dashboard",
  components: { ChartTimerPicker },
  data() {
    return {
      loading: true,
      goodsList: null,
      memberList: null,
      /** 基本数据 */
      info: {},
      advancedForm1: {
        times: "",
      },
      // 订单统计
      active: "day",
      activeName: "今日",
      // 退款订单统计
      active2: "day",
      activeName2: "今日",
      advancedForm2: {
        times: "",
      },
      order: '',
      shop: '',
      tradeData: {},
      tableData: {
        parta: [],
        partb: []
      },
      // 活动分类
      typeList: [],
      category_first: '',
      category_second: '',
      category_id: '',
      priceMilli0: '',
      priceMilli1: '',
    };
  },
  filters: {
    secrecyMobile(mobile) {
      mobile = String(mobile);
      if (!/\d{11}/.test(mobile)) {
        return mobile;
      }
      return mobile.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
    },
  },
  created() {
    this.GET_activeTypeGet();
  },
  activated() {},
  mounted() {
    this.$nextTick(() => {
      this.sesalChart = this.$echarts.init(
        document.getElementById("echarts_right")
      );
      this.sesalChartOrder = this.$echarts.init(
        document.getElementById("echarts_right_order")
      );
      this.sesalChartShop = this.$echarts.init(
        document.getElementById("echarts_right_shop")
      );
      this.sesalChartPrice = this.$echarts.init(
        document.getElementById("echarts_right_price")
      );
    });
  },
  methods: {
    /**  选择活动分类 */
    activityTypeChange(val) {
      this.category_first = null;
      this.category_second = null;
      this.category_id = ''
      this.typeList.forEach((item1) => {
        if (item1.value === val[0]) {
          this.category_first = item1.value;
          if (item1.children && item1.children.length > 0) {
            item1.children.forEach((item2) => {
              if (item2.value === val[1]) {
                this.category_second = item2.value;
              }
            });
          }
        }
      });
      this.Get_price(this.priceMilli0, this.priceMilli1);
    },
    //  查询活动分类列表
    GET_activeTypeGet() {
      API_activityType.activeTypeGet(0, {}).then((res) => {
        this.typeList = [];
        res.forEach((item1, index1) => {
          var jsonParent = {};
          jsonParent.value = item1.category_id;
          jsonParent.label = item1.name;
          var children = [];
          // if (item1.children && item1.children.length > 0) {
          //   item1.children.forEach((item2, index2) => {
          //     var jsonChild = {};
          //     jsonChild.value = item2.category_id;
          //     jsonChild.label = item2.name;
          //     children.push(jsonChild);
          //   });
          //   jsonParent.children = children;
          // }
          this.typeList.push(jsonParent);
        });
      });
    },
    handleActChange() {
      this.Get_trade_act();
    },
    handleTimeChangeTrade({ times, milli, activeName}) {
      this.Get_trade(milli[0], milli[1]);
    },
    handleTimeChangeOrder({ times, milli, activeName}) {
      this.Get_order(milli[0], milli[1]);
      this.activeName = activeName
    },
    handleTimeChangeShop({ times, milli, activeName}) {
      this.Get_shop(milli[0], milli[1]);
      this.activeName2 = activeName
    },
    handleTimeChangePrice({ times, milli, activeName}) {
      this.priceMilli0 = milli[0]
      this.priceMilli1 = milli[1]
      this.Get_price(milli[0], milli[1]);
    },
    Get_trade(start, end) {
      API_Sta.getTradeData({
        start,
        end
      }).then((res) => {
        this.tableData.parta = [{
          pv_num: +res.pv_num,
          uv_num: +res.uv_num,
          order_people: +res.order_people,
          order_num: +res.order_num,
          order_money: +res.order_money,

          pay_people: +res.pay_people,
          pay_orader: +res.pay_orader,
          pay_money: +res.pay_money,
          money: +res.money,
          refund_money: +res.refund_money,
        }]
        this.tableData.partb = [{
          pv_num: +res.pv_num,
          uv_num: +res.uv_num,
          order_people: +res.order_people,
          order_num: +res.order_num,
          order_money: +res.order_money,

          pay_people: +res.pay_people,
          pay_orader: +res.pay_orader,
          pay_money: +res.pay_money,
          money: +res.money,
          refund_money: +res.refund_money,
        }]
        const option = {
          title: {
            text: '',
            subtext: ''
          },
          tooltip: {
            trigger: 'item',
            formatter: "{b} : {c}<br/>" +
            `下单转化率(下单人数/浏览人数*100%)：${this.tableData.parta[0].uv_num === 0 ? '0' : (this.tableData.parta[0].order_people / this.tableData.parta[0].uv_num * 100).toFixed(0)}%<br/>
            付款转化率(付款人数/下单人数*100%)：${this.tableData.parta[0].order_people === 0 ? '0' : (this.tableData.parta[0].pay_people / this.tableData.parta[0].order_people * 100).toFixed(0)}%<br/>
            成交转化率(付款人数/浏览人数*100%)：${this.tableData.parta[0].uv_num === 0 ? '0' : (this.tableData.parta[0].pay_people / this.tableData.parta[0].uv_num * 100).toFixed(0)}%`
          },

          series: [
            {
              name: '漏斗图',
              type: 'funnel',
              left: '10%',
              top: 60,
              // x2: 80,
              bottom: 60,
              width: '80%',
              // height: {totalHeight} - y - y2,
              min: 0,
              max: 100,
              minSize: '0%',
              maxSize: '100%',
              sort: 'descending',
              gap: 2,
              label: {
                show: true,
                position: 'inside'
              },
              labelLine: {
                length: 10,
                lineStyle: {
                  width: 1,
                  type: 'solid'
                }
              },
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1
              },
              emphasis: {
                label: {
                  fontSize: 20
                }
              },
              data: [
                {value: this.tableData.parta[0].uv_num, name: '浏览'},
                {value: this.tableData.parta[0].order_people, name: '下单'},
                {value: this.tableData.parta[0].pay_people, name: '付款'}
              ]
            }
          ]
        };
        this.sesalChart.setOption(option);
      })
    },
    Get_order(start, end) {
      API_Sta.getOrderData({
        start,
        end
      }).then((res) => {
        this.loading = false;
        this.order = res
        const x = res.refundchart.xAxis;
        const s = res.refundchart.series;
        const map = new Map()
        x.forEach((e, i) => {
          const v = [s[0].localName[i] ? s[0].localName[i].split(',')[0] : '0', s[0].localName[i] ? s[0].localName[i].split(',')[1] : '0']
          map.set(e, v)
        });
        const mapDistri = new Map()
        x.forEach((e, i) => {
          const v = [s[1].localName[i] ? s[1].localName[i].split(',')[0] : '0', s[1].localName[i] ? s[1].localName[i].split(',')[1] : '0']
          mapDistri.set(e, v)
        });
        this.sesalChartOrder.setOption({
          backgroundColor: "#fff", // 背景色
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: x,
          },
          yAxis: {
            name: "",
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
          legend: {
            orient: "vertical",
            x: "right",
            y: "top",
            data: [s[0].name, s[1].name],
          },
          tooltip: {
            trigger: "axis",
          },
          tooltip1: {
            trigger: "axis",
            formatter(params) {
              const [p1, p2] = params
              var time, colora, colorb, a, b, c, d, e, f;
              time = p1.axisValueLabel
              colora = p1.color
              colorb = p2.color
              a = p1.value
              b = p2.value
              c = map.get(p1.axisValue)[0]
              d = mapDistri.get(p2.axisValue)[0]
              e = map.get(p1.axisValue)[1]
              f = mapDistri.get(p2.axisValue)[1]
              // console.log(params, 'params');
              return `<div style="width:100%;height:100;">
                        <div style="display:flex;justify-content:center;align-items:center;padding-top: 8px;">${time}</div>
                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div>订单数：</div>
                            </div>
                            <div class="item-value">
                              ${a}
                            </div>
                          </div>
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销订单数：</div>
                            </div>
                            <div class="item-value">
                             ${b}
                            </div>
                          </div>
                        </div>

                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div>订单总额：</div>
                            </div>
                            <div class="item-value">
                              ￥${c}
                            </div>
                          </div>
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销订单总额：</div>
                            </div>
                            <div class="item-value">
                              ￥${d}
                            </div>
                          </div>
                        </div>

                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;padding-bottom: 8px">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div>订单净收入：</div>
                            </div>
                            <div class="item-value">
                              ￥${e}
                            </div>
                          </div>
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销订单佣金支出：</div>
                            </div>
                            <div class="item-value">
                              ￥${f}
                            </div>
                          </div>
                        </div>
                      </div>`
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              name: s[0].name,
              data: s[0].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
            {
              name: s[1].name,
              data: s[1].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
          ],
        });
      });
    },
    Get_shop(start, end) {
      API_Sta.getShopData({
        start,
        end,
      }).then((res) => {
        this.loading = false;
        this.shop = res
        const x = res.shopchart.xAxis;
        const s = res.shopchart.series;
        const map = new Map()
        x.forEach((e, i) => {
          const v = [s[0].localName[i] ? s[0].localName[i].split(',')[0] : '0', s[0].localName[i] ? s[0].localName[i].split(',')[1] : '0']
          map.set(e, v)
        });
        const mapDistri = new Map()
        x.forEach((e, i) => {
          const v = [s[1].localName[i] ? s[1].localName[i].split(',')[0] : '0', s[1].localName[i] ? s[1].localName[i].split(',')[1] : '0']
          mapDistri.set(e, v)
        });
        this.sesalChartShop.setOption({
          backgroundColor: "#fff", // 背景色
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: x,
          },
          yAxis: {
            name: "",
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
          legend: {
            orient: "vertical",
            x: "right",
            y: "top",
            data: [s[0].name, s[1].name],
          },
          tooltip: {
            trigger: "axis",
          },
          tooltip1: {
            trigger: "axis",
            formatter(params) {
              const [p1, p2] = params
              var time, colora, colorb, a, b, c, d, e, f;
              time = p1.axisValueLabel
              colora = p1.color
              colorb = p2.color
              a = p1.value
              b = p2.value
              c = map.get(p1.axisValue)[0]
              d = mapDistri.get(p2.axisValue)[0]
              e = map.get(p1.axisValue)[1]
              f = mapDistri.get(p2.axisValue)[1]
              // console.log(params, 'params');
              return `<div style="width:100%;height:100;">
                        <div style="display:flex;justify-content:center;align-items:center;padding-top: 8px;">${time}</div>
                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div>订单数：</div>
                            </div>
                            <div class="item-value">
                              ${a}
                            </div>
                          </div>
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销订单数：</div>
                            </div>
                            <div class="item-value">
                             ${b}
                            </div>
                          </div>
                        </div>

                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;">
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div>退款总额：</div>
                            </div>
                            <div class="item-value">
                              ￥${c}
                            </div>
                          </div>
                          <div style="padding:0 20px;width:50%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销退款总额：</div>
                            </div>
                            <div class="item-value">
                              ￥${d}
                            </div>
                          </div>
                        </div>

                        <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;padding-bottom: 8px">
                          <div style="padding:0 20px;width:0%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:0px;height:0px;border-radius:100%;background-color:${colora};margin-right:5px;"></div>
                              <div></div>
                            </div>
                            <div class="item-value">
                            </div>
                          </div>
                          <div style="padding:0 20px;width:60%;display:flex;justify-content:space-between;align-items:center;">
                            <div style="display:flex;justify-content:space-between;align-items:center;">
                              <div style="width:10px;height:10px;border-radius:100%;background-color:${colorb};margin-right:5px;"></div>
                              <div>分销订单佣金收回：</div>
                            </div>
                            <div class="item-value">
                              ￥${f}
                            </div>
                          </div>
                        </div>
                      </div>`
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              name: s[0].name,
              data: s[0].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
            {
              name: s[1].name,
              data: s[1].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
          ],
        });
      });
    },
    Get_price(start, end) {
      if (this.category_first) {
        this.category_id = this.category_first
      }
      if (this.category_second) {
        this.category_id = this.category_second
      }
      API_Sta.getPriceData({
        start,
        end,
        category_id: this.category_id
      }).then((res) => {
        this.loading = false;
        const x = res.xAxis;
        const s = res.series;
        const option = {
          xAxis: {
            type: 'category',
            data: x
          },
          yAxis: {
            type: 'value'
          },
          tooltip: {
            trigger: "axis",
          },
          series: [{
            data: s.data,
            type: 'bar'
          }]
        };
        this.sesalChartPrice.setOption(option);
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.item-row {
  margin-bottom: 10px;
  .el-col {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
.item_list {
  background: #ffffff;
  border-radius: 12px;
  margin: 0 5px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    margin-top: 22px;
  }
  div {
    span {
      display: block;
    }
    span:nth-child(1) {
      color: #212121;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      color: #434343;
    }
  }
  img {
    width: 82px;
    height: 82px;
  }
}
.echart-title::after {
  display: none;
}
.echart-title {
  height: 50px;
  font-size: 18px;
  color: #007f7f;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:nth-child(2) {
    color: #212121;
    font-size: 14px;
  }
  p span {
    cursor: pointer;
    line-height: 20px;
    height: 24px;
    display: inline-table;
    margin: 0 5px;
  }

  p span.active {
    color: #007f7f;
    border-bottom: 2px solid #007f7f;
  }
}

.echart-alltitle {
  display: flex;
  justify-content: space-around;
}
.echarts-left1 {
  width: 15%;
}
.echarts-left50 {
  width: 50%;
  padding: 10px;
  ::v-deep .layout-container{
    height: auto;
    background-color: #fff;
  }
}
.echarts-left {
  height: 100px;
  width: 100%;
  background: rgba(51, 205, 189, 0.08);
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 0px;
    color: #212121;
  }
  p {
    font-size: 15px;
    line-height: 0px;
    font-weight: 500;
    margin-top: -10px;
    color: #656565;
  }
}
.echarts-left:nth-child(2),
.echarts-left:nth-child(3) {
  background: rgba(253, 85, 87, 0.08);
}
.echarts-right {
  width: 80%;
  height: 350px;
}

.elcard {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background: #f3f5f7;
    border-radius: 8px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #212121;
    margin-bottom: 8px;
    padding: 0 5px;
    cursor: pointer;
  }
}

.dashboard-container {
  height: 100%;
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .goods-statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    a {
      display: block;
      margin: 0;
      width: 75%;
      button {
        width: 100%;
      }
    }
  }
  .goods-image {
    width: 50px;
    height: 50px;
  }
}
.el-row {
  position: relative;
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
</style>
