<template>
  <div class="picker-title">
    <p>
      <span
        v-if="tags.includes('yesterday')"
        :class="active === 'yesterday' ? 'active' : 'no-active'"
        @click="handleTime('yesterday')"
        >昨日</span
      >
      <span
        v-if="tags.includes('today')"
        :class="active === 'today' ? 'active' : 'no-active'"
        @click="handleTime('today')"
        >今日</span
      >
      <span
        v-if="tags.includes('week')"
        :class="active === 'week' ? 'active' : 'no-active'"
        @click="handleTime('week')"
        >近一周</span
      >
      <span
        v-if="tags.includes('day7')"
        :class="active === 'day7' ? 'active' : 'no-active'"
        @click="handleTime('day7')"
        >近7天</span
      >
      <span
        v-if="tags.includes('day30')"
        :class="active === 'day30' ? 'active' : 'no-active'"
        @click="handleTime('day30')"
        >近30天</span
      >
      <span
        v-if="tags.includes('month1')"
        :class="active === 'month1' ? 'active' : 'no-active'"
        @click="handleTime('month1')"
        >近1个月</span
      >
      <span
        v-if="tags.includes('month3')"
        :class="active === 'month3' ? 'active' : 'no-active'"
        @click="handleTime('month3')"
        >近3个月</span
      >
      <span
        v-if="tags.includes('year1')"
        :class="active === 'year1' ? 'active' : 'no-active'"
        @click="handleTime('year1')"
        >近1年</span
      >
      <el-date-picker
        :default-time="['00:00:00', '23:59:59']"
        value-format="timestamp"
        style="width: 250px; margin-left: 10px"
        v-model="times"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="{
          disabledDate: MixinDisabledDate
        }"
        @change="handleTimeChange"
      ></el-date-picker>
    </p>
  </div>
</template>
<script>
export default {
  name: "ChartTimerPicker",
  props: {
    tags: {
      type: Array,
      default: (_) => [],
    },
    activeTag: {
      type: String,
      default: 'today',
    },
  },
  data() {
    return {
      active: "today",
      times: [],
    };
  },
  created() {
    this.activeTag && (this.active = this.activeTag);
    this.handleTime(this.active);
  },
  methods: {
    handleTime(item) {
      this.active = item;
      const end = new Date();
      const endYesterday = new Date().setHours(23, 59, 59) - 24 * 60 * 60 * 1000;
      const endToday = new Date().setHours(23, 59, 59);
      const hour0 = new Date().setHours(0, 0, 0, 0);
      const oneDayMillisecond = 24 * 60 * 60 * 1000;
      let start = null;
      let pastDay, activeName;

      switch (this.active) {
        case "yesterday":
          pastDay = 1;
          activeName = "昨日";
          break;
        case "today":
          pastDay = 0;
          activeName = "今日";
          break;
        case "week":
          pastDay = 6;
          activeName = "近一周";
          break;
        case "day7":
          pastDay = 6;
          activeName = "近7天";
          break;
        case "day30":
          pastDay = 30;
          activeName = "近30天";
          break;
        case "month1":
          pastDay = 30;
          activeName = "近1个月";
          break;
        case "month3":
          pastDay = 90;
          activeName = "近3个月";
          break;
        case "year1":
          pastDay = 365;
          activeName = "近1年";
          break;

        default:
          break;
      }
      start = new Date(hour0 - pastDay * oneDayMillisecond);
      let times = (this.times = [start, end]);
      if (this.active === 'yesterday') {
        times = (this.times = [start, endYesterday]);
      } else if (this.active === 'today') {
        times = (this.times = [start, endToday]);
      }
      const milli = [parseInt(times[0] / 1000), parseInt(times[1] / 1000)];
      this.$emit("time-change", {
        times,
        milli,
        activeName,
      });
    },
    handleTimeChange() {
      const milli = [
        parseInt(this.times[0] / 1000),
        parseInt(this.times[1] / 1000),
      ];
      this.active = "";
      const sMonth = new Date(this.times[0]).getMonth() + 1 + ''
      const sDay = new Date(this.times[0]).getDate() + ''
      const eMonth = new Date(this.times[1]).getMonth() + 1 + ''
      const eDay = new Date(this.times[1]).getDate() + ''
      this.$emit("time-change", {
        times: this.times,
        milli,
        activeName:
          ('00' + sMonth).substr(sMonth.length) + '/' + ('00' + sDay).substr(sDay.length) +
          "~" +
          ('00' + eMonth).substr(eMonth.length) + '/' + ('00' + eDay).substr(eDay.length),
      });
    },
  },
};
</script>
<style type="text/scss" lang="scss" scoped>
.picker-title {
  height: 50px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #212121;
  font-size: 14px;
  p:nth-child(2) {
    color: #212121;
    font-size: 14px;
  }
  p span {
    cursor: pointer;
    line-height: 20px;
    height: 24px;
    display: inline-table;
    margin: 0 5px;
  }

  p span.active {
    color: #007f7f;
    border-bottom: 2px solid #007f7f;
  }
}
</style>
