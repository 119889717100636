/**
 * 首页相关API
 */

import request from '@/utils/request'

/**
 * 首页响应
 * @param params
 */
export function getPage() {
  return request({
    url: '/admin/index/page',
    method: 'get',
    loading: false
  })
}

/**
 * 订单统计
 * @param params
 */
export function getOrderStatics(params) {
  return request({
    url: '/admin/index/page/orderM',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 退款统计
 * @param params
 */
export function getRefundStatics(params) {
  return request({
    url: '/admin/index/page/refundM',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 添加热门关键字
 * @param params
 */
export function addHotKeywords(params) {
  return request({
    url: 'admin/pages/hot-keywords',
    method: 'post',
    data: params,
    log: {
      type: '3',
      name: '热门关键字添加'
    }
  })
}

/**
 * 获取热门关键字详情
 * @param id
 */
export function getHotKeywordsDetail(id) {
  return request({
    url: `admin/pages/hot-keywords/${id}`,
    method: 'get'
  })
}

/**
 * 修改热门关键字
 * @param id
 * @param params
 */
export function editHotKeywords(id, params) {
  return request({
    url: `admin/pages/hot-keywords/${id}`,
    method: 'put',
    data: params,
    log: {
      type: '3',
      name: '热门关键字修改'
    }
  })
}

/**
 * 删除热门关键词
 * @param id
 */
export function deleteHotKeywords(id) {
  return request({
    url: `admin/pages/hot-keywords/${id}`,
    method: 'delete',
    log: {
      type: '3',
      name: '热门关键字删除'
    }
  })
}
