/**
 * 统计相关API
 */

import request from '@/utils/request'

/** 交易统计 */

/**
 * 交易数据
 * @param params
 */
export function getTradeData(params) {
  return request({
    url: 'admin/statistics/advertstatistics/tradepage',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 订单数据
 * @param params
 */
export function getOrderData(params) {
  return request({
    url: 'admin/statistics/advertstatistics/OrderM',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 商户数据
 * @param params
 */
export function getShopData(params) {
  return request({
    url: 'admin/statistics/advertstatistics/ShopM',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 单价数据
 * @param params
 */
export function getPriceData(params) {
  return request({
    url: 'admin/statistics/advertstatistics/MoneyM',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 活动分类统计
 * @param params
 */
export function getCatData(params) {
  return request({
    url: 'admin/statistics/activepage',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 访客来源
 * @param params
 */
export function getWhereAreYouFrom(params) {
  return request({
    url: 'admin/statistics/customersimplechart',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 广告位转化统计
 * @param params
 */
export function getAdData(params) {
  return request({
    url: 'admin/statistics/advertstatistics/activepage',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 分销活动转发排行
 * @param params
 */
export function getDistForward(params) {
  params.cycle_type = 'time'
  return request({
    url: 'admin/statistics/distribution/goods',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 转发活动所属分类排行
 * @param params
 */
export function getDistCat(params) {
  params.cycle_type = 'time'
  return request({
    url: 'admin/statistics/distribution/goodsCategory',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 转发活动所属商铺排行
 * @param params
 */
export function getDistShop(params) {
  params.cycle_type = 'time'
  return request({
    url: 'admin/statistics/distribution/goodsShop',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 新老客户统计
 * @param params
 */
export function getCustomerNewOrOld(params) {
  params.cycle_type = 'time'
  return request({
    url: 'admin/statistics/distribution/distributionUserStatistics',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客户性别统计
 * @param params
 */
export function getCustomerSex(params) {
  params.cycle_type = 'time'
  return request({
    url: 'admin/statistics/distribution/distributionUserSexStatistics',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客户地域统计
 * @param params
 */
export function getCustomerArea(params) {
  params.cycle_type = 'time'
  return request({
    url: 'admin/statistics/distribution/distributionUserAreaStatistics',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 分销活动转化率统计
 * @param params
 */
export function getActTrans(params) {
  params.cycle_type = 'time'
  return request({
    url: 'admin/statistics/distribution/distributionConversion',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客户类型及分布
 * @param params
 */
export function getUser4Block(params) {
  return request({
    url: 'admin/statistics/member/increase/memberClass',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客户类型及分布
 * @param params
 */
export function getUserTypePlatform(params) {
  return request({
    url: 'admin/statistics/member/increase/memberPvClass',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客户类型及分布
 * @param params
 */
export function getUserTypeOrder(params) {
  return request({
    url: 'admin/statistics/member/increase/memberPayClass',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客户类型及分布
 * @param params
 */
export function getSexPlatform(params) {
  params.cycle_type = 'time'
  return request({
    url: 'admin/statistics/member/increase/memberPvSex',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客户类型及分布
 * @param params
 */
export function getSexOrder(params) {
  params.cycle_type = 'time'
  return request({
    url: 'admin/statistics/member/increase/memberPaySex',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客户类型及分布
 * @param params
 */
export function getAreaPlatform(params) {
  params.cycle_type = 'time'
  return request({
    url: 'admin/statistics/member/increase/memberPvArea',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客户类型及分布
 * @param params
 */
export function getAreaOrder(params) {
  params.cycle_type = 'time'
  return request({
    url: 'admin/statistics/member/increase/memberPayArea',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客户类型及分布
 * @param params
 */
export function getComeFrom(params) {
  return request({
    url: 'admin/statistics/member/increase/memberRegister',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 订单及访问量统计
 * @param params
 */
export function getOrderTop20(params) {
  return request({
    url: 'admin/statistics/Shopstatistics/order',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 订单及访问量统计
 * @param params
 */
export function getShopTop20(params) {
  return request({
    url: 'admin/statistics/Shopstatistics/shop',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客单价及退款情况
 * @param params
 */
export function getPrice(params) {
  return request({
    url: 'admin/statistics/Shopstatistics/orderprice',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 客单价及退款情况
 * @param params
 */
export function getRefund(params) {
  return request({
    url: 'admin/statistics/Shopstatistics/refund',
    method: 'get',
    loading: false,
    params
  })
}
